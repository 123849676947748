// Generated by Framer (722666c)

import { addFonts, addPropertyControls, ComponentViewportProvider, ControlType, cx, CycleVariantState, getFonts, getPropertyControls, Link, SmartComponentScopedContainer, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import { Icon as Phosphor } from "https://framerusercontent.com/modules/tYScH7LTqUtz5KUaUAYP/p8dptk4UIND8hbFWz9V7/Phosphor.js";
const PhosphorFonts = getFonts(Phosphor);
const PhosphorControls = getPropertyControls(Phosphor);

const enabledGestures = {EptH6fZjc: {hover: true}};

const serializationHash = "framer-pcfns"

const variantClassNames = {EptH6fZjc: "framer-v-cmqdzy"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {bounce: 0.2, delay: 0, duration: 0.4, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion.create(React.Fragment)

const getProps = ({height, iconName, id, link, width, ...props}) => { return {...props, pjMbUVnoF: iconName ?? props.pjMbUVnoF ?? "XLogo", YdTA2k7Lp: link ?? props.YdTA2k7Lp} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;iconName?: string;link?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, pjMbUVnoF, YdTA2k7Lp, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({defaultVariant: "EptH6fZjc", enabledGestures, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const sharedStyleClassNames = []

const scopingClassNames = cx(serializationHash, ...sharedStyleClassNames)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}><Variants animate={variants} initial={false}><Transition value={transition1}><Link href={YdTA2k7Lp} motionChild nodeId={"EptH6fZjc"} openInNewTab><motion.a {...restProps} {...gestureHandlers} className={`${cx(scopingClassNames, "framer-cmqdzy", className, classNames)} framer-fgygfz`} data-framer-name={"primary"} layoutDependency={layoutDependency} layoutId={"EptH6fZjc"} ref={ref ?? ref1} style={{borderBottomLeftRadius: 201, borderBottomRightRadius: 201, borderTopLeftRadius: 201, borderTopRightRadius: 201, ...style}} {...addPropertyOverrides({"EptH6fZjc-hover": {"data-framer-name": undefined}}, baseVariant, gestureVariant)}><ComponentViewportProvider ><SmartComponentScopedContainer className={"framer-1ho240u-container"} isAuthoredByUser isModuleExternal layoutDependency={layoutDependency} layoutId={"np4eAVHsS-container"} nodeId={"np4eAVHsS"} rendersWithMotion scopeId={"HRa_2U6GS"} style={{opacity: 0.5}} variants={{"EptH6fZjc-hover": {opacity: 1}}}><Phosphor color={"var(--token-f195ea74-7512-4096-8d91-0e7c7e10d0ab, rgb(213, 219, 230))"} height={"100%"} iconSearch={"House"} iconSelection={pjMbUVnoF} id={"np4eAVHsS"} layoutId={"np4eAVHsS"} mirrored={false} selectByList style={{height: "100%", width: "100%"}} weight={"regular"} width={"100%"}/></SmartComponentScopedContainer></ComponentViewportProvider></motion.a></Link></Transition></Variants></LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-pcfns.framer-fgygfz, .framer-pcfns .framer-fgygfz { display: block; }", ".framer-pcfns.framer-cmqdzy { align-content: center; align-items: center; cursor: pointer; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 24px; height: min-content; justify-content: center; overflow: hidden; padding: 8px; position: relative; text-decoration: none; width: min-content; will-change: var(--framer-will-change-override, transform); }", ".framer-pcfns .framer-1ho240u-container { flex: none; height: 24px; position: relative; width: 24px; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-pcfns.framer-cmqdzy { gap: 0px; } .framer-pcfns.framer-cmqdzy > * { margin: 0px; margin-left: calc(24px / 2); margin-right: calc(24px / 2); } .framer-pcfns.framer-cmqdzy > :first-child { margin-left: 0px; } .framer-pcfns.framer-cmqdzy > :last-child { margin-right: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 40
 * @framerIntrinsicWidth 40
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]},"cztsiJSdI":{"layout":["auto","auto"]}}}
 * @framerVariables {"pjMbUVnoF":"iconName","YdTA2k7Lp":"link"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerHRa_2U6GS: React.ComponentType<Props> = withCSS(Component, css, "framer-pcfns") as typeof Component;
export default FramerHRa_2U6GS;

FramerHRa_2U6GS.displayName = "Elements/social media";

FramerHRa_2U6GS.defaultProps = {height: 40, width: 40};

addPropertyControls(FramerHRa_2U6GS, {pjMbUVnoF: PhosphorControls?.["iconSelection"] && {...PhosphorControls["iconSelection"], defaultValue: "XLogo", description: undefined, hidden: undefined, title: "Icon Name"}, YdTA2k7Lp: {title: "Link", type: ControlType.Link}} as any)

addFonts(FramerHRa_2U6GS, [{explicitInter: true, fonts: []}, ...PhosphorFonts], {supportsExplicitInterCodegen: true})